/* sponsors.css */
.sponsors-grid {
  padding: 60px 0;
}
.sponsors-grid  h1{
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .sponsors-grid__content {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 20px; 
  }

  
  .sponsor-container {
    width: 230px;
    padding-bottom: 50%; 
    position: relative;
    justify-self: center;
  }
  
  .sponsor-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

@media (max-width: 950px) {
  .sponsors-grid__content {
    grid-template-columns: repeat(2, 1fr); 
    gap: 20px; 
  }
}
  @media (max-width: 750px) {
   
    .sponsors-grid  h1{
      font-size: 25px;
    }
  }
  @media (max-width: 600px) {
    .sponsors-grid__content {
      grid-template-columns: repeat(1, 1fr); 
      gap: 20px; 
    }
  }