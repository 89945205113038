/* Header.css */
.header {
  top: 0;
  z-index: 1000;
  position: fixed;
  width: 100%;
  background-color: transparent; /* Fondo por defecto */
  transition: background-color 0.3s ease; /* Transición suave */
}

/* Esta clase se añade cuando se hace scroll */
.header.scrolled {
  background-color: rgba(0, 0, 0, 0.5);
}

.header__cont {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header nav {
  display: flex;
  align-items: center;
  transition: max-height 0.3s ease;
}

.header nav .container{
  display: flex;
  align-items: center;
}

.social-icons {
  font-size: 35px;
  display: flex;
  gap: 20px;
}

.social-icons a {
  color: white;
}

.header img {
  width: 100px; 
  height: 100px; 
  object-fit: cover;
}

.logo {
  color: white;
  font-weight: bold;
  z-index: 2;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menu li {
  display: inline;
  margin-right: 20px; 
}

.menu-link {
  color: #fff;
  text-decoration: none; 
  font-weight: bold;
}

.menu-link:hover {
  text-decoration: underline;
}

.menu-icon {
  display: none;
  font-size: 30px;
  cursor: pointer;
  color: white;
}

@media (max-width: 750px) {
  .header nav {
    display: none;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 100vh;
    padding-top: 70px; 
    z-index: 1;
  }

  .header nav.open {
    display: flex;
    transition: max-height 0.3s ease;
  }

  .header nav .container{
    display: block;
  }
  .menu {
    width: 100%;
    text-align: right;
  }

  .menu li {
    display: block;
    margin: 20px 0;
  }

  .menu-link {
    color: #DB6C65;
  }

  .social-icons {
    justify-content: flex-end;
    width: 100%;
    margin-top: 20px;
  }

  .menu-icon {
    display: block;
    font-size: 25px;
    z-index: 2;
  }

  .header img {
    width: 60px;
    height: 60px;
  }
}
