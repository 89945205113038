.hero {
  position: relative;
  /* height: 100vh; */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.hero-title {
  font-size: 40px; 
  color: white;
  margin-bottom: 10px;
}

.hero-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: bottom;
  position: relative;
}

.hero_image {
  position: relative;
  /* height: 100vh; */
}

/* Ocultar imagen móvil en dispositivos de escritorio */
.mobile {
  display: none;
}

/* Mostrar imagen móvil en dispositivos de tamaño pequeño */
@media (max-width: 1000px) {
  .hero-title {
    font-size: 30px;
    width: 100%;
  }

  /* Ocultar imagen de escritorio en dispositivos móviles */
  .desktop {
    display: none;
  }

  /* Mostrar imagen móvil en dispositivos móviles */
  .mobile {
    display: block;
    height: 100vw;
  }
}

button {
  padding: 10px 20px;
  background-color: #1C4857;
  color: black;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

button a {
  text-decoration: none;
  color: white;
}

button:hover {
  background-color: black; 
  color: white;
}
