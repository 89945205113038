/* Footer.css */
.footer {
    background-color: #1C4857;
    color: white;
    padding: 20px 0;
  }

  .footer-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  .footer-logo img {
    width: 100px;
  }

  @media (max-width: 750px) {
    .footer-logo img {
        width: 60px;
      }

    footer .social-icons {
    font-size: 25px;
    }
  }
  
  .footer p {
    text-align: center;
    font-size: 16px;
    color:white;
    margin-bottom: 0;
  }

  .footer p a {
    color: white;
    margin-top: 5px;
  }
