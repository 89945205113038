/* whatsapp.css */
.whatsapp-icon {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000; 
  }
  
  .whatsapp-icon img {
    width: 60px; 
    height: auto;
  }
  

  @media (max-width: 600px) {
    .whatsapp-icon img {
        width: 40px;
        height: 40px;
    }
  }