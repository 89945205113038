.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* styles.css */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hero {
  text-align: center;
}

.animated-block {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.9s ease-out, transform 0.9s ease-out;
}

.animated-block.visible {
  opacity: 1;
  transform: translateY(0);
}


.container {
  width: calc(100% - 60px);
  padding: 0 30px;
}


.google-form iframe {width: 100%;}
.google-form h2 {text-align: center;
  font-size: 40px;
  font-weight: 700;
  text-transform: uppercase;}
  @media (max-width: 750px) {
    .google-form h2    {
      font-size: 25px;
    }
    .container {
      width: calc(100% - 30px);
      padding: 0 15px;
    }

  }
  html {
    scroll-behavior: smooth;
  }
  

  /* claess */
  .clases-page {
    padding: 20px;
  }
  
  .clases-info {
    margin-bottom: 20px;
  }
  
  .formulario-inscripcion {
    margin-top: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  form input, form textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
  }
  
  form button {
    padding: 10px 20px;
    background-color: #007BFF;
    color: white;
    border: none;
    cursor: pointer;
  }
  
  form button:hover {
    background-color: #0056b3;
  }
  
  .ranking-button {
    background-color: #1C4857;
    color: white;
    font-weight: bold;
    padding: 12px 25px;
    border-radius: 50px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    margin-right: 15px;
    transition: all 0.3s ease;
  }
  
  .ranking-button:hover {
    background-color: white;
    color: #1C4857;
    transform: scale(1.05);
    box-shadow: 0 4px 10px rgba(28, 72, 87, 0.4);
  }
  
  .ranking-button:focus {
    outline: none;
    box-shadow: 0 0 10px rgba(28, 72, 87, 0.7);
  }
  
  @media (max-width: 750px) {
    .ranking-button {margin-right: 0;}
  }