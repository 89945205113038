/* Categoria.css */

.categorias h1 {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    text-transform: uppercase;
}


  .categorias__cont {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    padding: 0 150px;
  }
  

  .categoria-card {
    padding: 20px;
    background-color: #1C4857; 
    color: white;
    border-radius: 20px;
    width: calc(33.33% - 10px); 
    max-width: 300px;
    box-sizing: border-box;
  }

  .categoria-card:nth-child(4),
  .categoria-card:nth-child(5) {
    width: calc(33.33% - 10px); 
    max-width: 300px;
  }


  
  .categoria-card h2 {
    font-size: 24px;
    margin-bottom: 10px;
    text-align: center;
  }
  
  .categoria-card p {
    font-size: 16px;
    line-height: 1.5;
    text-align: center;
  }
  

  @media (max-width: 1100px) {
    .categorias__cont {
          padding: 0 50px; /* Reduce el padding */
      }

      .categoria-card {
          width: calc(50% - 10px); /* Dos columnas en pantallas medianas */
      }

      .categoria-card:nth-child(4),
      .categoria-card:nth-child(5) {
          width: calc(50% - 10px); /* Mantener dos columnas para los últimos elementos */
      }
  }

  @media (max-width: 750px) {
    .categorias__cont {
        padding: 0 20px; /* Padding más pequeño */
    }

    .categoria-card {
        width: 100%
    }
    .categoria-card:nth-child(4),
    .categoria-card:nth-child(5) {
        width: 100%;
    }
    .categorias h1 {
        font-size: 25px;
    }
  }

  @media (max-width: 480px) {
    .categorias__cont {
        padding: 0 10px; /* Padding mínimo para móviles */
    }
}